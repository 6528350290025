.client {
    text-align: center;
    .title {
        margin-bottom: 5vh;
    }
}

@media (min-width: 1200px) {
	.client {
        width: 90%;
        margin-left: 5%;
    
        &__bloc img {
            height: 80px;
        }
    }
}

@media (min-width: 1025px) and (max-width: 1199px) {
	.client {
        width: 100%;
    
        &__bloc img {
            height: 100px;
        }
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
	.client {
        width: 80%;
        margin-left: 10%;
    
        &__bloc img {
            height: 80px;
        }
    }
}

@media (min-width: 481px) and (max-width: 768px) {
	.client {
        width: 100%;
    
        &__bloc img {
            height: 80px;
        }
    }
}

@media (max-width: 480px) {
	.client {
        width: 100%;
    
        &__bloc img {
            height: 80px;
        }
    }
}