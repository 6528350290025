.firstLetter { font-weight: 900; }

.green { color:#8FDD39; }
.orange { color:#F87332; }
.pink { color:#ED078D; }
.blue { color:#26ABE6; }

.title {
    margin: 0;
    padding-top: 8vh;
    padding-bottom: 2vh;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 2.5rem;
}

// @media (min-width: 1201px) {
// 	.title {
//         font-size: 3rem;
//     }
// }

// @media (min-width: 1025px) and (max-width: 1200px) {
// 	.title {
//         font-size: 1.8rem;
//     }
// }

// @media (min-width: 769px) and (max-width: 1024px) {
// 	.title {
//         font-size: 1.7rem;
//     }
// }

// @media (min-width: 481px) and (max-width: 768px) {
// 	.title {
//         font-size: 1.5rem;
//     }
// }

// @media (max-width: 480px) {
// 	.title {
//         font-size: 1.2rem;
//     }
// }