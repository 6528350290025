body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (prefers-reduced-motion: no-preference) {
	.clic-logo {
		animation: logo-spin infinite 15s linear;
		pointer-events: none;
	}
}

@keyframes logo-spin {
	from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
}



html, body {
	margin: 0;
	padding: 0;
	overflow: hidden;
}
  
.red {
	background-color: #cf3535;
	background-image: none;
}
  

@import url("scrollbar.scss");
@import url("page.scss");
@import url("global-text.scss");
@import url("components/components.scss");