.project {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    #projects__iframe {
        width: 100%;
        height: 95vh;
        border: none;
    }
    &__bloc {
        // display: grid;
        // grid-template-columns: repeat(5, 1fr);
        // text-align: center;
        // width: 80%;
        // margin-left: 10%;

        // &__item {
        //     width: 90%;
        //     margin-left: 5%;
        //     margin-top: 50px;
        //     // border-radius: 20px;
        //     // background: #ffffff;
        //     // box-shadow:  20px 20px 40px #dedede,
        //     //             -20px -20px 40px #ffffff;
        
        //     &-logo {
        //         width: 100%;
        //         margin-top: 20px;
                
        //         &-img {
        //             height: 50px;
        //         }
        //     }

        //     &-text {
        //         width: 100%;
        //     }

        //     &-title {
        //         font-weight: bold;
        //     }
        // }
    }

    
}