.stat {
    // background: url("../images/clic.jpg") no-repeat;
    // background-size: cover;
    .title {
        margin-bottom: 5vh;
    }

    .stat__number {
        font-size: 2rem;
    }

    .stat__body {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        text-align: center;
    }
}