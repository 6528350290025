.presentation {
    height: 100vh;
    text-align: center;
    display: grid;

    .presentation-content {
        width: 80%;
        margin-left: 10%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-items: center;

        &__text {
            text-align: justify;
            font-size: 16px;
            width: 350px;
        }

        &__graph {
            overflow: hidden;
        }
    }

    #graph {
        width: 580px;
        height: 480px;
        border: none;
    }
}