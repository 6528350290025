.team {
    .title {
        color: white;
    }
    // text-align: center;
    // border-radius: 50%;
    // width: 1100px;
    // height: 1100px;
    // padding-top: 80px;
    overflow: hidden;
    text-align: center;
    height: 100vh;
    color: white;

    background: linear-gradient(145deg, #1e1e1e, #232323);
    
    &__bloc {
        font-size: 22px;
        width: 100%;
        margin-left: calc(50% - 600px); /* 300 = half of carousel size */
    }

    &__profile-picture {
        height: 150px;
    }
}