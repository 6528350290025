.container {
    display: block;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.panel {
    width: 100%;
    height: 100%;
    display: block;
}
  
.page {
    width: 100vw;
    height: 100vh;
}

.half-page {
    width: 100vw;
    height: 50vh;
    overflow: hidden;
}