.tech {
    max-width: 100%;
    text-align: center;
    &__logo {
        height: 85vh;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
    }
}

@media (min-width: 1201px) {
	.tech {
        &__logo {
            &__bloc {
                margin-bottom: 5vh;
                width: 25%;
            }
    
            &__img {
                height: 12vh;
            }
        }
    }
}

@media (min-width: 1025px) and (max-width: 1200px) {
	.tech {
        &__logo {
            &__bloc {
                margin-bottom: 5vh;
                width: 25%;
            }
    
            &__img {
                width: 140px;
            }
        }
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
	.tech {
        &__logo {
            &__bloc {
                margin-bottom: 5vh;
                width: 25%;
            }
    
            &__img {
                width: 140px;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: 768px) {
	.tech {
        &__logo {
            &__bloc {
                margin-bottom: 2vh;
                width: 33%;
            }
    
            &__img {
                width: 100px;
            }
        }
    }
}

@media (max-width: 480px) {
	.tech {
        &__logo {
            &__bloc {
                margin-bottom: 2vh;
                width: 33%;
            }
    
            &__img {
                width: 100px;
            }
        }
    }
}