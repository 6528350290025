.map-container {
    text-align: center;
	width: 100%;
	height: 50vh;
    display: block !important;
    overflow: visible !important;
}

.gm-style-iw button {display: none !important;}

.map-info {
    & img {
        height: 60px;
        padding: 5px;
    }
} 

.gm-style .gm-style-iw-c {
	padding: 0px;
}

.gm-style-iw-d {
    overflow: hidden !important;
}