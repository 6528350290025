.header {
	z-index: 200; /* cover carousel */
	text-transform: uppercase;
	display: grid;
	grid-template-columns: 1fr 4fr 1fr;
	position: -webkit-fixed;
	position: fixed;
	top: 0;
	background-color: white;
	transition: .5s;
    text-align: center;
    height: 7vh;
    font-size: 1.2rem;
    width: 100%;

    &__logo img { 
        transition: .5s;
        height: 5vh;
        margin: 1vh;
    }

    &__title { 
        max-width: 85vw;
        letter-spacing: .15rem;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            text-align: center;
            padding: 14px 16px;

            a {
                text-decoration: none;
            }
        }
    }
}

.shadow {
	box-shadow: 0px 0px 8px rgba(0,0,0,.6);
}